import React, { ReactElement, useMemo, useState } from "react"
import { Modal } from "semantic-ui-react"
import {
  CancelReturnRequestButton,
  ReturnRequestDescription,
  ReturnRequestFormCol,
  ReturnRequestFormInput,
  ReturnRequestFormRow,
  ReturnRequestModal,
  ReturnRequestModalActions,
  ReturnRequestModalButton,
  ReturnRequestModalButtonActive,
  ReturnRequestModalHeader,
  ReturnRequestTitle,
} from "./ReturnRequestModal.styled"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import { returnRequestLabels } from "./constants"
import {
  removeReturnRequest,
  RequestValidationInput,
  validateReturnRequest,
} from "services/graphql/mutations/parcel.mutations"
import { GetParcelReturnRequestQuery, ParcelReturnBtocRequest } from "types/parcel.types"
import { ReturnRequestModalTextField } from "./ReturnRequestModalTextField"
import { getReturnRequestConvertedValue, getValidationModalTexts } from "./helpers"
import { CarriersName } from "types/carrier.types"
import { CancelModal } from "components/CancelModal/CancelModal"

interface AdminRequestModalProps {
  isOpened: boolean
  onClose: () => void
  onSuccess: () => void
  requestDetails?: GetParcelReturnRequestQuery["getReturnRequestDetails"]
}

const AdminRequestModal = ({ isOpened, onClose, requestDetails, onSuccess }: AdminRequestModalProps): ReactElement => {
  const [code, setCode] = useState("")
  const [validateReturnRequestMutation, { loading }] = useMutation<boolean, RequestValidationInput>(
    validateReturnRequest,
  )

  const [removeReturnRequestMutation, { loading: deleteLoading }] = useMutation<boolean, { requestId: string }>(
    removeReturnRequest,
  )

  const codeData = useMemo(
    () => getValidationModalTexts(requestDetails?.order?.client?.name),
    [requestDetails?.order?.client?.name],
  )

  if (!requestDetails?.returnBtocRequest) return null

  const keysToIgnore = ["id", "parcelId", "createdAt", "updatedAt", "__typename", "email"]
  const formData = requestDetails.returnBtocRequest
  const formDataKeys = Object.keys(requestDetails.returnBtocRequest).filter(key => key && !keysToIgnore.includes(key))
  const address = requestDetails?.order.address
  const companyAddress = requestDetails?.order?.client.companyAddresses.find(
    companyAddress => companyAddress?.zipcodes?.includes(address?.zipcode),
  )

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setCode(value || "")
  }

  const onValidate = () => {
    const payload = {
      requestId: requestDetails?.returnBtocRequest.id,
      [codeData.key]: code,
    }
    validateReturnRequestMutation({
      variables: {
        payload,
      },
    })
      .then(() => {
        toast.success("Demande de retour à valider")
        onClose()
      })
      .catch(error => {
        toast.error(error.message)
      })
      .finally(() => onSuccess())
  }

  const getInfoFormattedLine = () => {
    const order = requestDetails?.order
    const keeperAddress = order.address.formattedLine
    const contacts = companyAddress?.companyAddressContacts
      ?.map(contact => `${contact.fullName} ${contact.phone}`)
      .join(" / ")

    return `Lieu de collecte : ${keeperAddress} 
    Lieu de livraison : Donner à l'accueil de l'agence Colissimo : dire que c'est un retour à l’expéditeur d’un colis Pickme - Contact sur place ${contacts}`
  }

  const onCancelReturnRequest = async () => {
    const result = await removeReturnRequestMutation({ variables: { requestId: requestDetails?.returnBtocRequest.id } })

    if (result.data) {
      onClose()
      onSuccess()
    }
  }

  return (
    <ReturnRequestModal open={isOpened}>
      <ReturnRequestModalHeader>
        <ReturnRequestTitle> Demande de retour à valider</ReturnRequestTitle>
        <ReturnRequestDescription>{codeData.title}</ReturnRequestDescription>
      </ReturnRequestModalHeader>

      <Modal.Content>
        <ReturnRequestFormRow>
          <ReturnRequestFormCol>
            {formDataKeys.map((key: ParcelReturnBtocRequest) => {
              let value = formData[key]
              const label = returnRequestLabels[key]
              value = getReturnRequestConvertedValue(key, value)

              return <ReturnRequestModalTextField value={value} key={key} label={label} />
            })}
          </ReturnRequestFormCol>
          <ReturnRequestFormCol>
            {requestDetails?.order?.client?.name === CarriersName.COLISSIMO && (
              <>
                <ReturnRequestModalTextField value={companyAddress.name} label={"Nom du destinataire"} />
                <ReturnRequestModalTextField value={companyAddress.phone} label={"Téléphone"} />

                <ReturnRequestModalTextField value={companyAddress.completeAddress} label={"Adresse du destinataire"} />
                <ReturnRequestModalTextField value={getInfoFormattedLine()} label={"Informations complémentaires"} />
              </>
            )}
          </ReturnRequestFormCol>
        </ReturnRequestFormRow>
        <ReturnRequestFormRow>
          <ReturnRequestFormCol>
            <ReturnRequestFormInput
              fluid
              placeholder={codeData.label}
              required
              value={code}
              onChange={onCodeChange}
              className="inputText"
            />
            <CancelModal
              title="En êtes-vous vraiment sûr.e ?"
              trigger={
                <CancelReturnRequestButton>
                  <span>Annuler la demande</span>
                </CancelReturnRequestButton>
              }
              onConfirm={onCancelReturnRequest}
              isLoading={deleteLoading}
            />
          </ReturnRequestFormCol>
        </ReturnRequestFormRow>
      </Modal.Content>
      <ReturnRequestModalActions>
        <ReturnRequestModalButton color="black" onClick={() => onClose()}>
          Annuler
        </ReturnRequestModalButton>
        <ReturnRequestModalButtonActive
          content="Valider"
          disabled={!code || loading}
          onClick={() => onValidate()}
          positive
        />
      </ReturnRequestModalActions>
    </ReturnRequestModal>
  )
}

export default AdminRequestModal
