import React from "react"
import { COLOR } from "utils/color"

export default function KeeperStatus({ status }) {
  const statuses = [
    {
      value: "NEW",
      color: COLOR.RED,
      text: "Nouveau",
    },
    {
      value: "IN_VALIDATION",
      color: COLOR.ORANGE,
      text: "Validation en cours",
    },
    {
      value: "PENDING",
      color: COLOR.CITRUS_YELLOW,
      text: "En attente",
    },
    {
      value: "VALIDATED",
      color: COLOR.BRIGHT_MEDIUM_GREEN,
      text: "Validé",
    },
    {
      value: "REJECTED",
      color: COLOR.DARK_CORAIL,
      text: "Rejeté",
    },
    {
      value: "OTHER",
      color: COLOR.MEDIUM_SUPER_LIGHT_GREY,
      text: "Autre",
    },
  ]

  let _status = statuses.find(i => i.value === status)
  if (_status === undefined) {
    _status = statuses.find(i => i.value === "OTHER")
  }

  return (
    <div className="keeper-status" style={{ backgroundColor: _status.color }}>
      {_status.text}
    </div>
  )
}
