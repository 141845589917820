import React from "react"
import { Loader } from "semantic-ui-react"

export const LoadingComponent = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "2em 0",
      }}
    >
      <Loader active inline />
    </div>
  )
}
