export enum COLOR {
  // RED
  SUPER_LIGHT_RED = "#FFF6F6",
  LIGHT_RED = "#E0B4B4",
  MEDIUM_LIGHT_RED = "#D86C6C",
  ROSE = "#ffae88",
  RED = "#DE5548",
  BRIGHT_RED = "#DC0000",
  DARK_RED = "#C5190E",
  SUPER_DARK_RED = "#9F3A38",

  DARK_CORAIL = "#F75C64",
  CORAIL = "#F76C73",
  LIGHT_CORAIL = "#F5818C",
  TOMATO = "#FA5656",

  // GREEN
  SUPER_LIGHT_GREEN = "#D4FBBB",
  LIGHT_GREEN = "#B3E891",
  MEDIUM_LIGHT_GREEN = "#33CC99",
  GREEN = "#55BA86",
  BRIGHT_GREEN = "#A7D86C",
  BRIGHT_MEDIUM_GREEN = "#6AC134",

  // GREY
  WHITE_SMOKE = "#F5F5F5",
  SUPER_LIGHT_GREY = "#E4E4E4",
  MEDIUM_SUPER_LIGHT_GREY = "#C0C0C0",
  LIGHT_GREY = "#A8A8A8",
  MEDIUM_LIGHT_GREY = "#9a9a9a",
  GREY = "#808080",
  MEDIUM_GREY = "#676767",
  MEDIUM_DARK_GREY = "#595959",
  DARK_GREY = "#424242",

  // BLUE
  SUPER_LIGHT_BLUE = "#DDE7FC",
  LIGHT_BLUE = "#BBDEFB",
  MEDIUM_LIGHT_BLUE = "#A8D5FB",
  BLUE = "#73A8E6",
  MEDIUM_BLUE = "#565EFA",
  MEDIUM_DARK_BLUE = "#081E9F",
  DARK_BLUE = "#192335",

  TURQUOISE = "#01A3A4",
  BRIGHT_TURQUOISE = "#00C7B1",

  // PURPLE
  PURPLE = "#A076DB",

  //YELLOW
  CITRUS_YELLOW = "#ECD819",
  YELLOW = "#FAD956",
  LIGHT_ORANGE = "#D8B06C",
  ORANGE = "#F2AA43",

  BLACK = "#000000",
  WHITE = "#FFFFFF",
}
