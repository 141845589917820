import * as yup from "yup"

export interface FormikState {
  parcel_no: string
  shipment_no: string
  shipper_name: string
  keeper_id: string
  company_id: string
  swap_order_no: string
  parcel_status: { value: string; label: string }
  reason: { value: string; label: string }
  details: string
  client_first_name: string
  client_last_name: string
  client_email: string
  client_phone: string
}

export const inputsText = {
  REQUIRED_FIELD: "Ce champ est obligatoire",
  INVALID_FIELD: "Ce champ n'est pas valide (ou manquant)",
  PHONE_INVALID: "Votre numéro de téléphone ne semble pas être valide",
  EMAIL_BAD_FORMAT: "Le format de l'email n'est pas bon",
  REQUIRED_AND_NUMBER: "Ce champ est obligatoire et doit être un nombre",
}

// eslint-disable-next-line no-control-regex, no-useless-escape
const emailRegex =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
const mobilePhoneRegex = /^(?:(?:\+|00)33|0)\s*(?:6|7)(?:[\s.-]*\d{2}){4}$|^(?:0033)\d{9}$/

export const createBtoCOrderSchema = yup.object({
  parcel_no: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
  swap_order_no: yup.string().strict().typeError(inputsText.REQUIRED_FIELD),
  shipment_no: yup.string().strict().typeError(inputsText.REQUIRED_FIELD),
  shipper_name: yup.string().strict().typeError(inputsText.REQUIRED_FIELD),
  parcel_status: yup
    .object()
    .shape({
      value: yup.string().required(inputsText.REQUIRED_FIELD).strict(),
      label: yup.string().required(inputsText.REQUIRED_FIELD).strict(),
    })
    .required(inputsText.REQUIRED_FIELD),
  reason: yup
    .object()
    .shape({
      value: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
      label: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
    })
    .required(inputsText.REQUIRED_FIELD),
  details: yup.string().strict(),
  client_first_name: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
  client_last_name: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
  client_email: yup
    .string()
    .ensure()
    .test("isEmailValid", inputsText.INVALID_FIELD, (value, textContext: { parent?: FormikState }) => {
      if (textContext.parent.client_phone && value === "") {
        return true
      }

      return emailRegex.test(value)
    })
    .typeError(inputsText.INVALID_FIELD),
  client_phone: yup
    .string()
    .ensure()
    .test("isPhoneValid", inputsText.INVALID_FIELD, (value, textContext: { parent?: FormikState }) => {
      if (textContext.parent.client_email && value === "") {
        return true
      }

      return (
        !!value &&
        mobilePhoneRegex.test(value) &&
        value.length > 1 &&
        value.replace(/((\+|00)33|0)([ -.]*[0-9]){9}/, "").trim().length === 0
      )
    })
    .typeError(inputsText.INVALID_FIELD),
})

export const createCtoBOrderSchema = yup.object({
  parcel_no: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
  parcel_status: yup
    .object()
    .shape({
      value: yup.string().required(inputsText.REQUIRED_FIELD).strict(),
      label: yup.string().required(inputsText.REQUIRED_FIELD).strict(),
    })
    .required(inputsText.REQUIRED_FIELD),
  reason: yup
    .object()
    .shape({
      value: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
      label: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
    })
    .required(inputsText.REQUIRED_FIELD),
  details: yup.string().strict(),
})
